body{
    margin: 0;
    /*color: #0f0f0f;*/
    color: #304155;
}

footer{
    display: block !important;
}

form h3{
    margin-bottom: 32px !important;
}

.ant-layout-has-sider{
    background: #041527;
}

.ant-descriptions-item-label {
    color: rgba(0,0,0,.45) !important;
}

span.ant-breadcrumb-link{
    color: #041527;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon{
    background: #52c41a !important;
    border: #52c41a !important;
}

.ant-steps-item-icon .ant-steps-finish-icon {
    color: white !important;
}